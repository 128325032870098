{
  "name": "astaryvisual",
  "version": "1.0.0",
  "description": "ASTAR",
  "source": [
    "src/index.html",
    "src/maptest/index.html"
  ],
  "repository": "https://github.com/webmsgr/astaryvisual.git",
  "author": "Wackery <zapcontact000@gmail.com>",
  "license": "MIT",
  "private": true,
  "scripts": {
    "build": "parcel build",
    "dev": "parcel"
  },
  "packageManager": "yarn@3.2.3",
  "dependencies": {
    "astary": "git+https://github.com/insberr/astary#9c9839c410426035276ef09a28cce750be220722",
    "comlink": "^4.3.1"
  },
  "devDependencies": {
    "@parcel/optimizer-data-url": "2.7.0",
    "@parcel/transformer-inline-string": "2.7.0",
    "@types/node": "^18.7.18",
    "eruda": "^2.5.0",
    "parcel": "^2.7.0"
  },
  "alias": {
    "stream": false,
    "process": false,
    "util": false,
    "path": false,
    "assert": false,
    "zlib": false,
    "buffer": false
  }
}
