import { createEndpoint, wrap } from "comlink"
import * as A from "astary"
import type { Node as n } from "astary"
const workerURL = new URL("./worker.ts",import.meta.url)
const worker = wrap<(typeof A)>(new Worker(workerURL))

export const Raycast = worker.Raycast;
export type Node = n;
export const svgToPaths = worker.svgToPaths;
export const generateNodes = worker.generateNodes;
export const AStar = worker.AStar;
export const randomNodes = worker.randomNodes;